.user-gold {
	background-color: #15344a;
	width: 17vw;
	height: 8vh;
	display: flex;
	justify-content: space-between;
	align-items: center;
	border: 2px solid #000;
	position: absolute;
	left: 7px;
	top: 0.4vh;
}

.gems {
	width: 3vw;
}

.plus {
	width: 3.4vw;
}

.user-gold span {
	font-size: 2.8vw;
	color: #fff;
	max-height: 100%;
}

.createClan {
	background-color: #1178ac;
	padding-bottom: 0;
	max-width: 100vw;
}

.createClan h1 {
	text-transform: uppercase;
	color: #fff;
	text-align: center;
	padding-top: 2vh;
	padding-bottom: 0vh;
	margin-bottom: 0;
	/* margin-top: 1vh; */
	font-size: 6vh;
}

.createClan .setNick {
	background-color: #0a6897;
	height: 12vh;
	display: flex;
	align-items: center;
}

.createClan .setCountry, .createClan .setAvatar {
	background-color: #0a6897;
	margin-top: 3vh;
}

.createClan .setAvatar {
	width: 30vw;
	display: flex;
	flex-direction: column;
}

.createClan .setCountry h3, .createClan .setAvatar h3 {
	display: inline-block;
	margin-top: 1vh;
	color: #429abd;
	width: 100%;
	text-align: center;
	font-size: 5vh;
	margin-bottom: 0;
}

.createClan  .setCountry h3 span {
	color: #fff;
}

.createClan  .setNick {
	display: flex;
}

.createClan  .error {
	font-size: 3vh;
	color: #fff;
	text-align: center;
	background: #0a6897;
	height: 4vh;
	min-height: 4vh;
	max-height: 4vh;
}

.createClan .setNick input {
	margin: 0 35px;
	padding: 4vh;
	height: 60%;
	width: 100%;
	font-size: 5vh;
	color: #1279ad;
	--placeholder-color: #1279ad;
}

.createClan .setNick input:focus {
	outline: none;
}

.createClan .setNick ::-webkit-input-placeholder {color:#1279ad;}

.createClan .setCountries {
	max-width: 100vw;
	overflow: scroll;
}

.createClan .countries,.createClan .avatars {
	display: flex;
	overflow-x: scroll;
	list-style-type: none;
	margin-bottom: 0;
	justify-content: center;
	padding-bottom: 2vh;
	max-height: 18vh;
}

.createClan .avatars {
	max-height: 27vh;
}

.createClan .countries {
	width: fit-content;
	width: -moz-fit-content;
}

.createClan .countries li, .createClan .avatars li {
	padding: 2vh;
	padding-bottom: 7vh;
	border: none;
	border-radius: 10px;
	position: relative;
}

.createClan .countries .active,.createClan .avatars .active {
	background-color: #fff;
	display: block;
}
.createClan .countries .active:after, .createClan .avatars .active:after {
	display: inline-block;
	content: "";
	width: 70px;
	height: 41px;
	background: transparent url(/images/choose.png) no-repeat;
	position: absolute;
	/* bottom: 20px; */
	left: 50%;
	bottom: 10px;
	transform: scale(0.5) translate(-100%, 36px);
}


.createClan .countries img, .createClan .avatars img {
	max-width: 5vw;
	-webkit-box-shadow: 4px 4px 8px 0px rgba(34, 60, 80, 0.2);
	-moz-box-shadow: 4px 4px 8px 0px rgba(34, 60, 80, 0.2);
	box-shadow: 4px 4px 8px 0px rgba(34, 60, 80, 0.2);
}

.createClan .ok {
	margin-bottom: 0;
	padding-bottom: 0;
	background-color: #60f100;
	width: 22vw;
	height: 9.9vh;
	color: #0b0b0b;
	text-align: center;
	font-size: 3vw;
	margin: 0 auto;
	display: block;
	margin-top: 1.6vh;
	border: 0.7vh solid #1f4f00;
	-webkit-box-shadow: 0px -0.7vh 0px 0px rgba(31, 79, 0, 0.51) inset;
	-moz-box-shadow: 0px -0.7vh 0px 0px rgba(31, 79, 0, 0.51) inset;
	box-shadow: 0px -0.7vh 0px 0px rgba(31, 79, 0, 0.51) inset;
}

.createClan .ok:focus {
	outline: none;
}

.createClan .ok span{
	margin-left: 2vw;
	font-size: 3vw;
	max-height: 100%;
}

.createClan .bottom {
	display: flex;
	justify-content: space-around;
}

.ava .avaLeft {
	width: 4vw;
	height: 7vw;
	/* max-width: 4vw; */
	transform: scale(-1, 1);
}

.ava .avaRight {
	width: 4vw;
	height: 7vw;
}

.desc {
	background-color: #0a6897;
	width: 65vw;
	margin-top: 3vh;
}

.desc h3 {
	display: inline-block;
	margin-top: 1vh;
	color: #429abd;
	width: 100%;
	text-align: center;
	font-size: 5vh;
	margin-bottom: 0;
}

.desc input {
	margin: 0 35px;
	padding: 4vh;
	height: 60%;
	width: 87%;
	font-size: 5vh;
	color: #1279ad;
	--placeholder-color: #1279ad;
}

.createClan .avatars li {
	display: none;
}

.createClan .avatars {
	width: 43%;
	padding-left: 0;
	justify-content: center;
}

.ava {
	display: flex;
	justify-content: space-evenly;
}

.createClan .avatars img {
	max-width: 6vw;
}

.create,.find  {
	box-sizing: content-box;
}

.create span {
	position: relative;
	left: 50%;
	transform: translate(-50%, 0);
	display: flex;
	align-items: center;
	align-content: center;
	justify-content: center;
}

.find span {
	position: relative;
	left: 50%;
	transform: translate(-50%, 0);
	display: flex;
	align-items: center;
	align-content: center;
	justify-content: center;
}