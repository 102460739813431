.register {
	background-color: #1178ac;
    padding-bottom: 0;
	max-width: 100vw;
}

.register h1 {
	text-transform: uppercase;
	color: #fff;
	text-align: center;
	padding-top: 2vh;
	padding-bottom: 0vh;
	margin-bottom: 0;
	/* margin-top: 1vh; */
	font-size: 6vh;
}

.register .setNick {
	background-color: #0a6897;
	height: 12vh;
	display: flex;
	align-items: center;
}

.register .setCountry, .register .setAvatar {
	background-color: #0a6897;
	margin-top: 3vh;
}

.register .setCountry h3, .register .setAvatar h3 {
	display: inline-block;
	margin-top: 1vh;
	color: #429abd;
	width: 100%;
	text-align: center;
	font-size: 5vh;
	margin-bottom: 0;
}

.register  .setCountry h3 span {
	color: #fff;
}

.register  .setNick {
	display: flex;
}

.register  .error {
	font-size: 3vh;
	color: #fff;
	text-align: center;
	background: #0a6897;
	height: 4vh;
	min-height: 4vh;
	max-height: 4vh;
}

.register .setNick input {
	margin: 0 35px;
	padding: 4vh;
	height: 60%;
	width: 100%;
	font-size: 5vh;
	color: #1279ad;
	--placeholder-color: #1279ad;
}

.register .setNick input:focus {
	outline: none;
}

.register .setNick ::-webkit-input-placeholder {color:#1279ad;}

.register .setCountries {
	max-width: 100vw;
	overflow: scroll;
}

.register .countries,.register .avatars {
	display: flex;
	overflow-x: scroll;
	list-style-type: none;
	margin-bottom: 0;
	justify-content: center;
	padding-bottom: 2vh;
	max-height: 18vh;
}

.register .countries {
	width: fit-content;
	width: -moz-fit-content;
}

.register .countries li, .register .avatars li {
	margin-right: 1vw;
	padding: 2vh;
	padding-bottom: 7vh;
	border: none;
	border-radius: 10px;
	position: relative;
}

.register .countries .active,.register .avatars .active {
	background-color: #fff;
}

.register .avatars li {
	padding-bottom: 18vh;
}

.register .avatars {
	padding-bottom: 21vh;
	overflow-y: hidden;
}

.register .countries .active:after, .register .avatars .active:after {
    display: inline-block;
    content: "";
    width: 70px;
    height: 41px;
    background: transparent url(/images/choose.png) no-repeat;
    position: absolute;
    /* bottom: 20px; */
    left: 50%;
    bottom: 10px;
	transform: scale(0.5) translate(-100%, 36px);
}


.register .countries img, .register .avatars img {
	max-width: 5vw;
    -webkit-box-shadow: 4px 4px 8px 0px rgba(34, 60, 80, 0.2);
    -moz-box-shadow: 4px 4px 8px 0px rgba(34, 60, 80, 0.2);
    box-shadow: 4px 4px 8px 0px rgba(34, 60, 80, 0.2);
}

.register .ok {
	margin-bottom: 0;
	padding-bottom: 0;
	background-color: #60f100;
	width: 24.2vw;
	height: 9.9vh;
	border: 0.7vh solid #1f4f00;
	color: #0b0b0b;
	text-align: center;
	font-size: 3vw;
	margin: 0 auto;
	display: block;
	margin-top: 1.6vh;
	-webkit-box-shadow: 0px -0.7vh 0px 0px rgba(31, 79, 0, 0.51) inset;
	-moz-box-shadow: 0px -0.7vh 0px 0px rgba(31, 79, 0, 0.51) inset;
	box-shadow: 0px -0.7vh 0px 0px rgba(31, 79, 0, 0.51) inset;
}

.register .ok:focus {
    outline: none;
}