.joinclan {
    display: flex;
    justify-content: space-between;
}

.joinclan .select {
    background-color: #1178ac;
    display: flex;
    position: relative;
}
.joinclan .profile {
    width: 45%;
    background-color: #00689d;
    min-height: 100vh;
    padding-top: 10vh;
}


.joinclan .icon {
    margin-right: 1vw;
}

.joinclan .icon img {
    width: 5vw;
}

.joinclan .player {
    display: flex;
    position: relative;
    align-items: center;
    margin-bottom: 10vh;
    padding-left: 2vw;
    padding-right: 2vw;
}

.joinclan .player .settings {
    position: absolute;
    right: 0;
    top: 50%;
    transform: translate(0, -50%);
}
.joinclan .settings img {
    width: 5vw;
    margin-top: -4px;
}

.joinclan .profile .nick {
    font-size: 2vw;
    color: #fff;
    max-width: 360px;
    overflow-wrap: break-word;
}


.joinclan .buttons {
    display: flex;
    justify-content: center;
    margin-top: 0vh;
}

.joinclan .buttons button {
    background-color: #60f100;
    width: 14vw;
    height: 14vw;
    border: 3px solid #1f4f00;
    -webkit-box-shadow: 0px -9px 5px -5px rgba(34, 60, 80, 0.6) inset;
    -moz-box-shadow: 0px -9px 5px -5px rgba(34, 60, 80, 0.6) inset;
    box-shadow: 0px -9px 5px -5px rgba(34, 60, 80, 0.6) inset;
    position: relative;
    display: flex;
    align-items: flex-end;
    flex-direction: column;
}

.joinclan .buttons button img {
    width: 5vw;
    text-align: center;
    position: relative;
    display: inline-block;
    top: -2vh;
}

.joinclan .buttons button span {
    height: 100%;
    color: #0b0b0b;
    text-align: center;
    top: 8px;
    font-size: 4vw;
    width: 100%;
    text-transform: uppercase;
    position: relative;
}

.joinclan .create:after, .joinclan .find:after {
    display: inline-block;
    content: "";
    width: 141px;
    height: 141px;
    position: absolute;
    /* bottom: 20px; */
    left: 0;
    top: 34px;
    transform: scale(0.3) translate(-35%, -112px);
}

.joinclan .create {
    margin-right: 140px;
}

.joinclan .create:after {
    /*background: transparent url(/images/icon_create.png) no-repeat;*/
}
.joinclan .find:after {
    /*background: transparent url(/images/icon_find.png) no-repeat;*/
}

.joinclan .find-clan {
    padding-top: 6vh;
    padding-bottom: 6vh;
    display: flex;
    justify-content: space-between;
    align-items: baseline;
    border-top: 1px solid #004b71;
}

.joinclan .find-clan a {
    text-transform: uppercase;
    color: #4c95ba;
    opacity: 1;
    font-size: 3vw;
}

.joinclan .find-clan .find_icon {
    width: 2.3vw;
    opacity: 0.3;
}

.joinclan .info .top img {
    height: 2.3vw;
    position: relative;
    transform: translate(0, -13%);
    padding-left: 0.7vw;
}

.joinclan .find-clan .right_icon {
    width: 1.4vw;
    opacity: 0.3;
}

.joinclan .info {
    display: flex;
    flex-direction: column;
    width: 100%;
}

.info .top {
    display: flex;
    justify-content: space-between;
    width: 100%;
}

.info .top div {
    display: flex;
    flex-direction: row;
    align-items: center;
}

.joinclan .info span {
    display: inline-block;
    color: #9edeff;
    text-align: center;
    font-size: 5vh;
    padding-left: 0.5vw;
}

.joinclan .clan-name span {
    color: #fff;
}


.joinclan .menu {
    width: 100%;
    padding-top: 0vh;
}

.joinclan .menu .info img {
    width: 1.5vw;
}

.joinclan .menu h1 {
    text-align: center;
    font-size: 10vh;
}

.joinclan .menu h1 span {
    color: #fff;
}

.joinclan .profile .menu button {
    border: none;
    display: flex;
    justify-content: space-between;
    flex-direction: row;
    padding: 1vw;
    background-color: #00689d;
    align-items: center;
    opacity: 0.3;
}

.joinclan .profile .menu button.active {
    background-color: #4c95ba;
    opacity: 1;
}

.joinclan .profile .menu button.active span {
    color: #fff;
}

.joinclan .clan-info {
    width: 50vw;
    padding-top: 15vh;
    margin-right: 2.2vw;
}

.joinclan .all-buffs {
    max-width: 100vw;
    overflow: scroll;
}

.joinclan .ul-buffs {
    display: flex;
    overflow-x: scroll;
    list-style-type: none;
    margin-bottom: 0;
    justify-content: center;
    padding-bottom: 2vh;
    width: fit-content;
    width: -moz-fit-content;
    padding-left: 0;
}

.joinclan .ul-buffs li {
    margin-right: 1vw;
    padding: 3vh;
    border: none;
    border-radius: 10px;
    position: relative;
    opacity: 0.9;
    background-color: gray;
}

.joinclan .ul-buffs li.good {
    background-color: #2987b6;
}

.joinclan .ul-buffs li a {
    display: flex;
    flex-direction: column;
    align-items: center;
    align-content: center;
    justify-content: space-between;
}

.buff-info {
    display: none;
    padding-top: 10vh;
    position: absolute;
    width: 100vw;
    height: 100vh;
    background-color: rgba(0, 104, 156, 0.9);
    z-index: 999;
    justify-content: space-around;
    flex-direction: column;
    align-content: center;
    align-items: center;
}

.buff-info.flex {
    display: flex;
}

.joinclan .buff-info .ok {
    padding: 1vh;
    width: 22vw;
    background-color: #60f100;
    border: 0.7vh solid #1f4f00;
    color: #0b0b0b;
    text-align: center;
    font-size: 3vw;
    margin: 0 auto;
    display: block;
    margin-top: 1.6vh;
    -webkit-box-shadow: 0px -0.7vh 0px 0px rgba(31, 79, 0, 0.51) inset;
    -moz-box-shadow: 0px -0.7vh 0px 0px rgba(31, 79, 0, 0.51) inset;
    box-shadow: 0px -0.7vh 0px 0px rgba(31, 79, 0, 0.51) inset;
}

.joinclan .buff-info .ok:focus {
    outline: none;
}

.buff-info img {
    height: 20vh;
    min-height: 20vh;
}

.buff-info h2 {
    color: #fff;
    font-size: 7vh;
}

.buff-info .level {
    display: flex;
}

.buff-info .level span.buff-level {
    background-color: #990101;
    color: #fff;
}

.buff-info .level span {
    color: #61f907;
    padding: 1vh 2vh;
    font-size: 3vw;
}

.joinclan .ul-buffs li span {
    color: #fff;
    text-align: center;
    font-size: 5vh;
}

.joinclan .ul-buffs img {
    width: 7vw;
    max-width: 7vw;
    -webkit-box-shadow: 4px 4px 8px 0px rgba(34, 60, 80, 0.2);
    -moz-box-shadow: 4px 4px 8px 0px rgba(34, 60, 80, 0.2);
    box-shadow: 4px 4px 8px 0px rgba(34, 60, 80, 0.2);
}



.clan-info .infos .label {
    border: none;
    display: flex;
    justify-content: space-between;
    flex-direction: row;
    padding: 1vw;
    background-color: #00689d;
    align-items: center;
    opacity: 1;
    margin-top: 2vh;
}

.clan-info .infos .label span {
    display: inline-block;
    color: #9edeff;
    text-align: center;
    font-size: 5vh;
    padding-left: 0.5vw;
}

.clan-info .infos .label .name span {
    color: #9edeff;
}

.clan-info .infos .label .clan-id {
    color: #fff;
}

.clan-info .infos .label .clan-country {
    color: #feffcc;
}


.clan-info .infos .label .clan-activity {
    color: #feffcc;
    width: 100%;
    text-align: end;
}

.clan-info .infos .label .text img {
    max-width: 5vw;
}

.clan-info .infos .label .text {
    width: 50%;
    display: flex;
    justify-content: space-around;
    align-items: center;
}

.clan-join{
    display: flex;
    background-color: #60f100;;
    border: 2px solid #000;
    margin: 0 auto;
    align-items: center;
    padding: 1vh;
    -webkit-box-shadow: 0px -0.7vh 0px 0px rgba(31, 79, 0, 0.51) inset;
    -moz-box-shadow: 0px -0.7vh 0px 0px rgba(31, 79, 0, 0.51) inset;
    box-shadow: 0px -0.7vh 0px 0px rgba(31, 79, 0, 0.51) inset;
}

.clan-join.clan-join-limit {
    background-color: #ff3d00;
}

.clan-join img {
    max-width: 4vw;
}

.clan-join span {
    text-transform: uppercase;
    color: #000;
    font-size: 2vw;
    padding: 1vh;
}

.settings-clan {
    position: absolute;
    top: 0;
    right: 8vw;
    opacity: 1;
}

.settings-clan img {
    width: 4.5vw;
}
