@font-face {
	font-family: 'Neusa Next Pro Compact';
	src: local('Neusa Next Pro Compact Regular'), local('NEUSANEXTPRO-COMPACTMEDIUM'),
	url('../../fonts/NEUSANEXTPRO-COMPACTMEDIUM.TTF') format('truetype');
	font-weight: normal;
	font-style: normal;
}

@font-face {
	font-family: 'Neusa Next Pro Compact';
	src: local('Neusa Next Pro Compact Bold'), local('NEUSANEXTPRO-COMPACTBOLD'),
	url('../../fonts/NEUSANEXTPRO-COMPACTBOLD.TTF') format('truetype');
	font-weight: bold;
	font-style: normal;
}

body {
	height: 100vh;
	overflow-y: hidden;
}

.dev-lable {
	position: absolute;
	bottom: 1vw;
	right: 1vw;
	font-size: 2vw;
	color: #0b0b0b;
	z-index: 99999;
}

.app {
	font-family: Neusa Next Pro Compact;
	font-style: normal;
	min-height: 100vh;
	background-color: #1178ac;
}

.close {
    position: absolute;
    top: 1vh;
    right: 20px;
    opacity: 1;
	width: 5vw;
}

.close button {
	border: none;
	background: none;
}

.close img {
	width: 3.6vw;
}

.disabled{
	pointer-events:none;
}

.register .elements .disabled {
	background-color: #8f968a;
}

.createClan .elements .disabled {
	background-color: #8f968a;
}


button:focus {
	outline: none;
}

a:focus {
	outline: none;
}

.update {
	position: absolute;
	left: 0;
	top: 0;
	background-color: #01699e;
	opacity: 0.95;
	z-index: 9998;
	height: 100vh;
	width: 100vw;
	display: flex;
	flex-direction: column;
	align-items: center;
	/* margin-bottom: 2vh; */
	justify-content: space-evenly;
}

.update img {
	width: 20vw;
}

.update h2 {
	color: #fff;
	font-size: 3vw;
}

.client-update {
	font-size: 4vw;
	display: flex;
	background-color: #60f100;
	border: 2px solid #000;
	margin: 0 auto;
	align-items: center;
	padding: 1vh 8vh;
	-webkit-box-shadow: 0px -0.7vh 0px 0px rgba(31, 79, 0, 0.51) inset;
	-moz-box-shadow: 0px -0.7vh 0px 0px rgba(31, 79, 0, 0.51) inset;
	box-shadow: 0px -0.7vh 0px 0px rgba(31, 79, 0, 0.51) inset;
	color: #000;
	text-decoration: none;
}