.clan {
    display: flex;
    justify-content: space-between;
}

.clan .select {
    background-color: #1178ac;
    display: flex;
    position: relative;
}
.clan .profile {
    width: 45%;
    background-color: #00689d;
    min-height: 100vh;
    padding-top: 10vh;
}


.clan .icon {
    margin-right: 1vw;
}

.clan .icon img {
    width: 5vw;
}

.clan .player {
    display: flex;
    position: relative;
    align-items: center;
    margin-bottom: 10vh;
    padding-left: 2vw;
    padding-right: 2vw;
}

.clan .player .settings {
    position: absolute;
    right: 0;
    top: 50%;
    transform: translate(0, -50%);
}
.clan .settings img {
    width: 5vw;
    margin-top: -4px;
}

.clan .profile .nick {
    font-size: 2vw;
    color: #fff;
    max-width: 360px;
    overflow-wrap: break-word;
}


.clan .buttons {
    display: flex;
    justify-content: center;
    margin-top: 0vh;
}

.clan .buttons button {
    background-color: #60f100;
    width: 14vw;
    height: 14vw;
    border: 3px solid #1f4f00;
    -webkit-box-shadow: 0px -0.7vh 0px 0px rgba(34, 60, 80, 0.6) inset;
    -moz-box-shadow: 0px -0.7vh 0px 0px rgba(34, 60, 80, 0.6) inset;
    box-shadow: 0px -0.7vh 0px 0px rgba(34, 60, 80, 0.6) inset;

    position: relative;
    display: flex;
    align-items: flex-end;
    flex-direction: column;
}

.clan .buttons button img {
    width: 5vw;
    text-align: center;
    position: relative;
    display: inline-block;
    top: -2vh;
}

.clan .buttons button span {
    height: 100%;
    color: #0b0b0b;
    text-align: center;
    top: 8px;
    font-size: 4vw;
    width: 100%;
    text-transform: uppercase;
    position: relative;
}

.clan .create:after, .clan .find:after {
    display: inline-block;
    content: "";
    width: 141px;
    height: 141px;
    position: absolute;
    /* bottom: 20px; */
    left: 0;
    top: 34px;
    transform: scale(0.3) translate(-35%, -112px);
}

.clan .create {
    margin-right: 140px;
}

.clan .create:after {
    /*background: transparent url(/images/icon_create.png) no-repeat;*/
}
.clan .find:after {
    /*background: transparent url(/images/icon_find.png) no-repeat;*/
}

.clan .find-clan {
    padding-top: 6vh;
    padding-bottom: 6vh;
    display: flex;
    justify-content: space-between;
    align-items: baseline;
    border-top: 1px solid #004b71;
}

.clan .find-clan a {
    text-transform: uppercase;
    color: #4c95ba;
    opacity: 1;
    font-size: 3vw;
}

.clan .find-clan .find_icon {
    width: 2.3vw;
    opacity: 0.3;
}

.clan .info .top img {
    height: 2.3vw;
    position: relative;
    transform: translate(0, -13%);
    padding-left: 0.7vw;
}

.clan .find-clan .right_icon {
    width: 1.4vw;
    opacity: 0.3;
}

.clan .info {
    display: flex;
    flex-direction: column;
    width: 100%;
}

.info .top {
    display: flex;
    justify-content: space-between;
    width: 100%;
}

.info .top div {
    display: flex;
    flex-direction: row;
    align-items: center;
}

.clan .info span {
    display: inline-block;
    color: #9edeff;
    text-align: center;
    font-size: 5vh;
    padding-left: 0.5vw;
}

.clan .clan-name span {
    color: #fff;
}


.clan .menu {
    width: 100%;
    padding-top: 0vh;
}

.clan .menu .info img {
    width: 1.5vw;
}

.clan .menu h1 {
    text-align: center;
    font-size: 10vh;
}

.clan .menu h1 span {
    color: #fff;
}

.clan .profile .menu button {
    border: none;
    display: flex;
    justify-content: space-between;
    flex-direction: row;
    padding: 1vw;
    background-color: #00689d;
    align-items: center;
    opacity: 1;
}

.clan .profile .menu button span {
    opacity: 0.3;
}

.clan .profile .menu button.active span {
    opacity: 1;
}

.clan .profile .menu button.disabled {
    opacity: 0.3;
}

.clan .profile .menu button.active {
    background-color: #4c95ba;
    opacity: 1;
    outline: none;
}

.clan .clan-info {
    width: 55vw;
    padding-top: 15vh;
    /*margin-right: 2.2vw;*/
}

.clan.upgrade .clan-info {
    padding-top: 6vh;
}

.clan .clan-info .elements {
    display: flex;
    list-style-type: none;
    margin-bottom: 22vh;
    justify-content: start;
    flex-direction: column;
    overflow-y: scroll;
    max-height: 62vh;
    width: 50vw;
}

.clan.upgrade {
    background-color: #003551;
}

.clan .upgrades {
    display: flex;
    flex-direction: column;
    align-items: center;
    position: relative;
    height: 100vh;
}

.clan .upgrades .progress-exp {
    position: absolute;
    bottom: 0;
    right: 0;
    width: 100%;
    background-color: #1c8cc5;
    display: flex;
    padding: 3vh;
    padding-bottom: 8vh;
    justify-content: center;
}

.clan .progress-exp__count span {
    font-size: 3vw;
    height: 4vw;
    color: #fff;
    padding-left: 1vw;
    text-shadow: 2px 1px 2px black;
}


.clan .countStar {
    position: absolute;
    top: 5.3vw;
}

.clan .progress-exp__count {
    display: flex;
    align-items: center;
}

.clan .progress-exp__icon {
    width: 4vw;
}

.clan .progress-exp__value {
    display: flex;
    flex-direction: column;
    width: 40vw;
    align-items: center;
}

.clan .progress-exp__value span {
    color: #fff;
    font-size: 3vw;
    text-shadow: 2px 1px 2px black;
}

.clan  .progress-exp__bar {
    height: 3vh;
    width: 90%;
    background-color: #4b2300;
    border: 2px solid #000;
}

.clan .progress-exp__progress {
    height: 1.9vh;
    width: 90%;
    background-color: #ffcc00;
}

.clan .upgrades h2 {
    color: #fff;
    text-align: center;
    font-size: 3vw;
    margin: 0;
}


.clan .upgrades > img {
    text-align: center;
    width: 75vh;
    top: -3vh;
    position: relative;
}

.clan .elements .buff {
    background-color: #00689d;
    border: none;
    margin-bottom: 3vh;
    cursor: not-allowed;
    display: flex;
    padding: 1vh;
    flex-direction: column;
}

.clan .elements .buff-user {
    display: flex;
}

.clan .elements .buff .ok {
    padding: 0.5vh 9vh;
    /* width: 22vw; */
    background-color: #60f100;
    border: 0.7vh solid #1f4f00;
    color: #0b0b0b;
    text-align: center;
    font-size: 2vw;
    margin: 0 auto;
    display: block;
    margin-top: 1.6vh;
    -webkit-box-shadow: 0px -0.7vh 0px 0px rgba(31, 79, 0, 0.51) inset;
    -moz-box-shadow: 0px -0.7vh 0px 0px rgba(31, 79, 0, 0.51) inset;
    box-shadow: 0px -0.7vh 0px 0px rgba(31, 79, 0, 0.51) inset;
}

.clan .elements .buff .ok.disabled {
    background-color: #8f968a;
    cursor: not-allowed;
}

.clan .elements .buff .ok.disabled .progress-exp__count {
    background-color: #8f968a;
    cursor: not-allowed;
}

.clan .buff .ok .progress-exp__icon {
    width: 2.5vw;
}

.clan .buff .ok .progress-exp__count {
    background-color: #307900;
    border-radius: 10%;
    padding: 0.1vw 2vw;
    margin-bottom: 1vh;
}

.clan .buff .ok .progress-exp__count span {
    font-size: 2vw;
    height: 2.5vw;
    color: #fff;
    padding-left: 1vw;
    text-shadow: 2px 1px 2px black;
}

.clan .elements .buff:focus {
    outline: none;
}

.clan .buff .icon img {
    width: 7vw;
}

.clan .elements .buff .buff-text {
    text-align: left;
    padding-right: 4vh;
}

.clan .elements .buff .buff-text span {
    color: #fff;
    font-size: 4.5vh;
    padding-left: 0;
}

.clan .profile .menu button span {
    color: #fff;
    opacity: 1;
}

.clan .buff-levels span.buff-level {
    background-color: #990101;
    color: #fff;
}

.clan .buff-levels {
    text-align: left;
}

.clan .buff-levels span {
    color: #61f907;
    padding: 0vh 1vh;
    font-size: 2.5vw;
    width: 10vw;
}

.clan .buff-levels .buff-current {
    color: #fff;
    width: 5vw;
    padding: 0vh 2vw;
}

.clan .buff-levels .buff-factor {
    width: fit-content;
    padding: 0vh 2vw;
}

.clan-info .infos {
    width: 50vw;
    margin: 0 auto;
}

.clan-info .infos .label {
    border: none;
    display: flex;
    justify-content: space-between;
    flex-direction: row;
    padding: 1vw;
    background-color: #00689d;
    align-items: center;
    opacity: 1;
    margin-top: 2vh;
}

.clan-info .infos .label span {
    display: inline-block;
    color: #9edeff;
    text-align: center;
    font-size: 5vh;
    padding-left: 0.5vw;
}

.clan-info .infos .label .name span {
    color: #9edeff;
}

.clan-info .infos .label .clan-id {
    color: #fff;
}

.clan-info .infos .label .clan-country {
    color: #feffcc;
}


.clan-info .infos .label .clan-activity {
    color: #feffcc;
    width: 100%;
    text-align: end;
}

.clan-info .infos .label .text img {
    max-width: 5vw;
}

.clan-info .infos .label .text {
    width: 50%;
    display: flex;
    justify-content: space-around;
    align-items: center;
}

.clan-leave {
    display: flex;
    background-color: #ff4e00;
    border: 2px solid #000;
    margin: 0 auto;
    align-items: center;
    padding: 1vh;
    -webkit-box-shadow: 0px -0.7vh 0px 0px rgba(31, 79, 0, 0.51) inset;
    -moz-box-shadow: 0px -0.7vh 0px 0px rgba(31, 79, 0, 0.51) inset;
    box-shadow: 0px -0.7vh 0px 0px rgba(31, 79, 0, 0.51) inset;
}

.clan-leave img {
    max-width: 3vw;
}

.clan-leave span {
    text-transform: uppercase;
    color: #000;
    font-size: 2vw;
    padding: 1vh;
}

.settings-clan {
    position: absolute;
    top: 0;
    right: 8vw;
    opacity: 1;
}

.settings-clan img {
    width: 4.5vw;
}

.warning-modal-bg {
    left: 0;
    top: 0;
    position: absolute;
    width: 100vw;
    height: 100vh;
    background-color: rgba(110, 96, 97, 0.87);
    z-index: 8;
    display: none;
}


.warning-modal {
    background-color: #efffff;
    border-radius: 5%;
    z-index: 9;
    padding: 2vw 8vw 2vw 8vw;
    position: absolute;
    left: 50%;
    top: 50%;
    transform: translate(-50%, -50%);
    display: none;
    flex-direction: column;
}

.warning-modal.show, .warning-modal-bg.show {
    display: flex;
}

.warning-modal span {
    margin-top: 4vh;
    margin-bottom: 4vh;
    font-size: 3vw;
    text-align: center;
}

.warning-modal .warning-buttons {
    display: flex;
    align-items: flex-end;
    flex-direction: row;
    justify-content: space-between;
}

.warning-modal .warning-buttons button{
    background-color: #60f100;
    padding: 1vw 3vw;
    font-size: 2vw;
    border: 0.7vh solid #223c50;
    -webkit-box-shadow: 0px -0.7vh 0px 0px rgba(34, 60, 80, 0.6) inset;
    -moz-box-shadow: 0px -0.7vh 0px 0px rgba(34, 60, 80, 0.6) inset;
    box-shadow: 0px -0.7vh 0px 0px rgba(34, 60, 80, 0.6) inset;
    position: relative;
    margin-top: 1vh;
    margin-left: 2vw;
}


.warning-modal .warning-buttons button.ok {
    background-color: #ff4e00;
}