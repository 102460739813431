.clanChat {
    background-color: #003551;
    display: flex;
    position: relative;
}
.clanChat .profile {
    width: 33vw;
    background-color: #00689d;
    min-height: 100vh;
    padding-top: 10vh;
    padding-left: 2vw;
    padding-right: 2vw;
}

.clanChat .menu {
    width: 65%;
    padding-top: 3vh;
}


.clanChat .menu img {
    width: 32vw;
    margin: 0 auto;
    text-align: center;
    display: flex;
}

.clanChat .menu h1 {
    text-align: center;
    font-size: 10vh;
}

.clanChat .menu h1 span {
    color: #fff;
}

.clanChat .icon {
    margin-right: 1vw;
}

.clanChat .icon img {
    width: 5vw;
}

.clanChat .player {
    display: flex;
    position: relative;
    align-items: center;
    margin-bottom: 10vh;
}

.clanChat .player .settings {
    position: absolute;
    right: 0;
    top: 50%;
    transform: translate(0, -50%);
}
.clanChat .settings img {
    width: 5vw;
    margin-top: -4px;
}

.clanChat .profile .nick {
    font-size: 2vw;
    color: #fff;
    max-width: 360px;
    overflow-wrap: break-word;
}


.clanChat .buttons {
    display: flex;
    justify-content: center;
    margin-top: 0vh;
}

.clanChat .buttons button {
    background-color: #60f100;
    width: 14vw;
    height: 14vw;
    border: 5px solid #1f4f00;
    -webkit-box-shadow: 0px -7px 0px 0px rgba(31, 79, 0, 0.51) inset;
    -moz-box-shadow: 0px -7px 0px 0px rgba(31, 79, 0, 0.51) inset;
    box-shadow: 0px -7px 0px 0px rgba(31, 79, 0, 0.51) inset;
    position: relative;
    display: flex;
    align-items: flex-end;
    flex-direction: column;
}

.clanChat .buttons button img {
    width: 5vw;
    text-align: center;
    position: relative;
    display: inline-block;
    top: -2vh;
}

.clanChat .buttons button span {
    height: 100%;
    color: #0b0b0b;
    text-align: center;
    top: 8px;
    font-size: 4vw;
    width: 100%;
    text-transform: uppercase;
    position: relative;
}

.clanChat .create:after, .clanChat .find:after {
    display: inline-block;
    content: "";
    width: 141px;
    height: 141px;
    position: absolute;
    /* bottom: 20px; */
    left: 0;
    top: 34px;
    transform: scale(0.3) translate(-35%, -112px);
}

.clanChat .create {
    margin-right: 140px;
}

.clanChat .create:after {
    /*background: transparent url(/images/icon_create.png) no-repeat;*/
}
.clanChat .find:after {
    /*background: transparent url(/images/icon_find.png) no-repeat;*/
}

.clanChat .find-clan {
    padding-top: 6vh;
    padding-bottom: 6vh;
    display: flex;
    justify-content: space-between;
    align-items: baseline;
    border-top: 1px solid #004b71;
}

.clanChat button.find-clan  {
    background: none;
    border: none;
    border-top: 1px solid #004b71;
    width: 100%;
}

.clanChat .find-clan a {
    opacity: 1;
    font-size: 2.5vw;
    color: #fff;
}

.clanChat .my-clan a{
    margin-left: 0.6vw;
    margin-right: 0.2vw;
}

.clanChat .find-clan .disable {
    color: #4c95ba;
}

.clanChat .find-clan.disable a  {
    color: #4c95ba;
}

.clanChat .find-clan .find_icon {
    display: inline-block;
    width: 2.3vw;
}
.clanChat .find-clan.disable .find_icon {
    opacity: 0.3;
}

.clanChat .find-clan.disable .right_icon {
    opacity: 0.3;
}

.clanChat .find-clan .right_icon {
    width: 1.4vw;
}
.clanChat .find-clan .right_icon.disable {
    opacity: 0.3;
}

.clanChat .my-clan {
    align-items: center;
}

.clanChat .my-clan .find_icon {
    width: 5vw;
}

.clanChat .chat-box {
    width: 60vw;
    margin-left: 2vw;
}

.clanChat .chat_list {
    list-style-type: none;
    padding: 0;
    padding-top: 5vh;
    padding-bottom: 15vh;
    margin: 0;
    max-height: 100vh;
    overflow-y: scroll;
}

.clanChat .chat_list li {
    padding: 2vh;
    font-size: 2vw;
    color: #fff;
    margin-bottom: 2vh;
    width: 100%;
    overflow-wrap: anywhere;
}

.clanChat .chat_list li img {
    width: 3vw;
}

.clanChat .chat_list .message {
    display: flex;
    flex-direction: column;
    background-color: #00456a;
}

.clanChat .chat_list .message .top {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    width: 100%;
    margin-bottom: 1vh;
}

.clanChat .user-name-top1 {
    padding-left: 2vw;
}

.chat_list .join_clan {
    background-color: #009966;
}
.chat_list .join_clan span {
    padding-left: 2vw;
}
.chat_list .leaving_clan span {
    padding-left: 2vw;
}

.chat_list .leaving_clan {
    background-color: #c84726;
}

.clanChat .chat_list .message.self_message {
    background-color: #00689d;
}

.send-form {
    position: absolute;
    right: 1vw;
    bottom: 1vh;
}

.send-form textarea {
    padding: 3vh;
    padding-right: 10vh;
    width: 64vw;
    font-size: 2vw;
    height: 11vh;
    min-height: 11vh;
    max-height: 11vh;
}

.send-form textarea:focus {
    outline: none;
}

.send-form textarea::-webkit-input-placeholder { /* Chrome */
    color: #013652;
}
.send-form textarea:-ms-input-placeholder { /* IE 10+ */
    color: #013652;
}
.send-form textarea::-moz-placeholder { /* Firefox 19+ */
    color: #013652;
    opacity: 1;
}
.send-form textarea:-moz-placeholder { /* Firefox 4 - 18 */
    color: #013652;
    opacity: 1;
}

.send-form__error {
    position: absolute;
    right: 0;
    opacity: 0;
}

.send-form .send {
    position: absolute;
    right: 1vw;
    bottom: 55%;
    background: #1178ac;
    border: none;
    padding: 1vh 2vh;
    transform: translateY(50%);
}

.send-form .send img {
    width: 4vh;
}

.send-form .send.disable {
    cursor: not-allowed;
    background-color: gray;
}