.select {
    background-color: #1178ac;
    display: flex;
    position: relative;
}
.select .profile {
    width: 30%;
    background-color: #00689d;
    min-height: 100vh;
    padding-top: 10vh;
    padding-left: 2vw;
    padding-right: 2vw;
}

.select .profile .find-clan {
    color: #fff2f2;
    opacity: 1;
}

.select .menu {
    width: 65%;
    padding-top: 3vh;
}


.select .menu img {
    width: 32vw;
    margin: 0 auto;
    text-align: center;
    display: flex;
}

.select .menu h1 {
    text-align: center;
    font-size: 10vh;
}

.select .menu h1 span {
    color: #fff;
}

.select .icon {
    margin-right: 1vw;
}

.select .icon img {
    width: 5vw;
}

.select .player {
    display: flex;
    position: relative;
    align-items: center;
    margin-bottom: 10vh;
}

.select .player .settings {
    position: absolute;
    right: 0;
    top: 50%;
    transform: translate(0, -50%);
}
.select .settings img {
    width: 5vw;
    margin-top: -4px;
}

.select .profile .nick {
    font-size: 2vw;
    color: #fff;
    max-width: 360px;
    overflow-wrap: break-word;
}


.select .buttons {
    display: flex;
    justify-content: center;
    margin-top: 0vh;
}

.select .buttons button {
    background-color: #60f100;
    width: 14vw;
    height: 14vw;
    border: 0.7vh solid #1f4f00;
    -webkit-box-shadow: 0px -0.7vh 0px 0px rgba(31, 79, 0, 0.51) inset;
    -moz-box-shadow: 0px -0.7vh 0px 0px rgba(31, 79, 0, 0.51) inset;
    box-shadow: 0px -0.7vh 0px 0px rgba(31, 79, 0, 0.51) inset;
    position: relative;
    display: flex;
    align-items: flex-end;
    flex-direction: column;
}

.select .buttons button img {
    width: 5vw;
    text-align: center;
    position: relative;
    display: inline-block;
    top: -2vh;
}

.select .buttons button span {
    height: 100%;
    color: #0b0b0b;
    text-align: center;
    top: 8px;
    font-size: 4vw;
    width: 100%;
    text-transform: uppercase;
    position: relative;
}

.select .create:after, .select .find:after {
    display: inline-block;
    content: "";
    width: 141px;
    height: 141px;
    position: absolute;
    /* bottom: 20px; */
    left: 0;
    top: 34px;
    transform: scale(0.3) translate(-35%, -112px);
}

.select .create {
    margin-right: 140px;
}

.select .create:after {
    /*background: transparent url(/images/icon_create.png) no-repeat;*/
}
.select .find:after {
    /*background: transparent url(/images/icon_find.png) no-repeat;*/
}

.select .find-clan {
    padding-top: 6vh;
    padding-bottom: 6vh;
    display: flex;
    justify-content: space-between;
    align-items: baseline;
    border-top: 1px solid #004b71;
}

.select .find-clan a {
    text-transform: uppercase;
    color: #fff;
    opacity: 1;
    font-size: 3vw;
}

.select .find-clan.disabled .find_icon {
    opacity: 0.3;
}
.select .find-clan.disabled .right_icon {
    opacity: 0.3;
}

.select .find-clan.disabled a{
    opacity: 0.3;
}

.select .find-clan .find_icon {
    width: 2.3vw;
}

.select .find-clan .right_icon {
    width: 1.4vw;
}