.findClan {
    max-height: 100vh;
}

.findClan .clans {
    display: flex;
}

.findClan .clans .elements {
    width: 45vw;
}

.findClan .clans .clan {
    background-color: #00689d;
    padding: 1vh 3vh;
    margin-bottom: 2vh;
    align-items: center;
    border: none;
}

.findClan .clans .clan.active {

}

.country-clan img {
}


.findClan .setCountries {
    max-width: 100vw;
    overflow: scroll;
}

.findClan .countries {
    display: flex;
    overflow-x: scroll;
    list-style-type: none;
    margin-bottom: 0;
    justify-content: center;
    padding-bottom: 2vh;
    max-height: 18vh;
    width: fit-content;
    width: -moz-fit-content;
}

.findClan .countries li {
    margin-right: 1vw;
    padding: 2vh;
    padding-bottom: 7vh;
    border: none;
    border-radius: 10px;
    position: relative;
}


.findClan .countries .active {
    background-color: #fff;
}

.findClan .countries img {
    max-width: 5vw;
    -webkit-box-shadow: 4px 4px 8px 0px rgba(34, 60, 80, 0.2);
    -moz-box-shadow: 4px 4px 8px 0px rgba(34, 60, 80, 0.2);
    box-shadow: 4px 4px 8px 0px rgba(34, 60, 80, 0.2);
}

.findClan .clan-info {
    width: 50vw;
    margin-top: 2vh;
    margin-left: 2vw;
}

.findClan .clan-info .setCountry {
    background-color: #00689d;
}

.findClan .setCountry h3 {
    display: inline-block;
    margin-top: 1vh;
    color: #429abd;
    width: 100%;
    text-align: center;
    font-size: 5vh;
    margin-bottom: 0;
}

.findClan  .setCountry h3 span {
    color: #fff;
}

.findClan .clan-info h2 {
    color: #ffff;
    padding-bottom: 2vh;
    border-bottom: 2px solid #00689d;
}

.findClan .setNick {
    margin-bottom: 2vh;
}

.findClan .setNick input {
    padding: 3vh;
    height: 60%;
    width: 100%;
    font-size: 5vh;
    color: #1279ad;
    --placeholder-color: #1279ad;
}

.findClan .setNick input:focus {
    outline: none;
}

.findClan .setNick ::-webkit-input-placeholder {color:#1279ad;}

.findClan .clans .clan .clan-name {
    text-align: left;
}

.findClan .clans .clan .clan-name span {
    max-width: 26vw;
    overflow-wrap: break-word;
    text-align: left;
}

.findClan .clans .clan .top span:first-child {
    min-width: 5vw;
    text-align: left;
}

.findClan .clans .clan .info .top div {
    display: flex;
    flex-direction: row;
    align-items: center;
    min-width: 9vw;
    text-align: center;
}

.findClan .clans .elements {
    display: flex;
    list-style-type: none;
    margin-bottom: 0;
    justify-content: start;
    flex-direction: column;
    overflow-y: scroll;
    max-height: 100vh;
    margin-top: 2vh;
    margin-left: 1vw;
}


.findClan .clans .ok {
    padding: 1vh;
    background-color: #60f100;
    width: 22vw;
    border: 0.7vh solid #1f4f00;
    color: #0b0b0b;
    text-align: center;
    font-size: 3vw;
    margin: 0 auto;
    display: block;
    margin-top: 1.6vh;
    -webkit-box-shadow: 0px -0.7vh 0px 0px rgba(31, 79, 0, 0.51) inset;
    -moz-box-shadow: 0px -0.7vh 0px 0px rgba(31, 79, 0, 0.51) inset;
    box-shadow: 0px -0.7vh 0px 0px rgba(31, 79, 0, 0.51) inset;
}

.findClan .clans .ok:focus {
    outline: none;
}