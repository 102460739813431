.loading {
    min-width: 100vw;
    min-height: 100vh;
    z-index: 9999;
    background-image: url("/images/loading_screen_clans.jpg");
    background-size: 100vw;
    background-repeat: round;
}

.bar {
    min-width: 100vw;
    min-height: 100vh;
    height: 100%;
    transform: scaleX(0);
    z-index: 9999;
    background-image: url("/images/progress_bar_full_small.png");
    background-size: 100vw;
    animation-name: sizes;
    animation-duration: 4s;
    animation-delay: 1s;
    animation-iteration-count: 1;
    animation-timing-function: linear;
    animation-fill-mode: forwards;
    background-repeat: round;
}

.bar img {
    z-index: 10000;
    transform: scaleX(0);
    animation-name: sizes;
    animation-duration: 3s;
    animation-delay: 1s;
    animation-iteration-count: 1;
    animation-timing-function: linear;
    animation-fill-mode: forwards;
}

.loading .icon {
    position: absolute;
    left: 28.8vw;
    top: 8.2vh;
    width: 12vw;
}

.loading .text {
    position: absolute;
    left: 44vw;
    top: 8.9vh;
    font-size: 5vh;
    color: #fff;
    max-width: 26vw;
}


@keyframes sizes {
    0% {transform: scaleX(0); opacity: 0.8}
    100% {transform: scaleX(1); opacity: 1}
}

